import {Component, computed, EventEmitter, input, Output, signal,} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {ProductService} from '@core/services/product.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddToCartComponent} from '../add-to-cart/add-to-cart.component';
import {AddToCartPopupComponent} from '../add-to-cart/add-to-cart-popup/add-to-cart-popup.component';
import {AsyncPipe, CurrencyPipe, JsonPipe} from '@angular/common';
import {GroupVoucherService} from '@core/services/groupVoucher.service';
import {PricePipe} from '@core/pipes/price.pipe';
import {TrackEventService} from '@core/services/track-event.service';
import {ProductPriceService} from '@core/services/product-price.service';

export type PriceLinePrefix = [string, string] | [string];

export interface PriceLine {
  isDiscounted: boolean;
  prefix?: PriceLinePrefix | undefined
  label: string;
  price: number;
  currencyCode: string;
  isStriked?: boolean;
  showVatText?: boolean;
  qty?: number | null;
  unitName?: string | null;
}

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, AsyncPipe, AddToCartComponent, AddToCartPopupComponent, PricePipe, JsonPipe]
})
export class PriceTableComponent {

  alwaysShowDiscountBtn = input<boolean>(true);
  hideProductDefaultCta = input<boolean | null>(false);
  eventSourceName = input.required<string>();
  hitPosition = input<number>();
  hideAddToCart = input<boolean>(false);
  product = input.required<WineProduct | undefined>();

  priceLines = computed(() => {
    const product = this.product();
    if (!product) {
      return [];
    }
    const groupVoucher = this.groupVoucherService.groupVoucherSignal().groupVoucher;
    const lines = this.productService.getFormattedProductPricesSignal(this.product)();
    return this.productPriceService.formatPriceLines(groupVoucher, product, lines);
  });

  @Output() addedToCart: EventEmitter<any> = new EventEmitter<any>();

  public showAddedToCart = signal(false);
  addedToCartTimeout: any;

  constructor(
    private productService: ProductService,
    private modal: NgbModal,
    private groupVoucherService: GroupVoucherService,
    private trackEventService: TrackEventService,
    private productPriceService: ProductPriceService
  ) {
  }

  get groupVoucher() {
    return this.groupVoucherService.currentGroupVoucher();
  }

  hasGroupVoucherPrice = computed(() => {
    const product = this.product()
    return !!this.groupVoucher?.prices?.find(d => d?.vismaId == product?.vismaProductNumber)
  })

  addedToCartEvent($event: boolean) {
    if (!$event) {
      return;
    }
    this.showAddedToCart.set(true);
    this.addedToCart.emit();
    this.addedToCartTimeout = setTimeout(() => {
      this.showAddedToCart.set(false);
    }, 2500);
  }

  buyMore() {
    clearTimeout(this.addedToCartTimeout);
    this.showAddedToCart.set(false);
  }

  openContactForOffersModal() {
    this.trackEventService.trackClickEvent('contact-for-offers', {
      product: this.product()?.name,
      source: this.eventSourceName(),
      vismaId: this.product()?.vismaProductNumber
    });
    import('../../modals/basic-page-modal/basic-page-modal.component').then(comp => {
      const ref = this.modal.open(comp.BasicPageModalComponent);
      (ref.componentInstance).path = 'info/storkoeb';
    })
  }
}

