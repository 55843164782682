@if (product()?.productImageBadgeType === 'last_bottles') {
  <span class="last-bottles-title">1</span>
  <span class="last-bottles-subtitle">
    stk. pris
  </span>
} @else if (product()?.productImageBadgeType === 'show_discount') {
  <span class="label">SPAR</span>
  <span class="price">
    {{ (lastPrice()?.amountIncVat | price) - (lastPrice()?.discount?.amountIncVat | price) | currency }}
  </span>
}
