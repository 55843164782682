import {ChangeDetectionStrategy, Component, effect, HostBinding, input} from '@angular/core';
import {NgIf} from '@angular/common';
import {Rating} from '@core/interfaces/generated/graphql';
import {ExistsPipe} from '@core/pipes/exists.pipe';

@Component({
  selector: 'app-product-rating-points',
  templateUrl: './product-rating-points.component.html',
  styleUrls: ['./product-rating-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ExistsPipe]
})
export class ProductRatingPointsComponent {

  rating = input.required<Rating>();

  @HostBinding('title') title = '';

  constructor() {
    effect(() => {
      if (this.rating().label?.length) {
        this.title = this.rating().label ?? '';
      } else if (this.rating().starCount) {
        this.title = `${this.rating().rating} af ${this.rating().starCount} stjerner`;
      } else {
        this.title = `${this.rating().rating} Point`;
      }
    });
  }

}
