@if (!product()?.hidePriceTable) {
  <div class="field" data-rr-shared="true">
    @for (priceLine of priceLines(); track priceLine.price + '-' + priceLine.label) {
      <div
        [class.is-discounted]="priceLine.isDiscounted"
        [class.is-striked]="priceLine.isStriked && !priceLine.isDiscounted"
        class="item">
        <div class="quantity d-flex flex-wrap">

          @if (priceLine.prefix?.length === 1) {
            <strong class="col-12 secret-discounted-price">{{ priceLine.prefix?.[0] }}</strong>
          }
          @if (priceLine.prefix?.length === 2) {
            <strong class="text-center d-flex flex-column discounted-price">
              <span class="font-weight-normal discount-not-highlighted">{{ priceLine.prefix?.[0] }}</span>
              {{ priceLine.prefix?.[1] }}
            </strong>
          }
          {{ priceLine.label }}
        </div>
        <div class="price-wrap">
          <div
            [class.text-decoration-line-through]="priceLine.isStriked && priceLine.isDiscounted"
            class="price"
          >
            {{ priceLine.price | price | currency }}
            <span class="currency-code">{{ priceLine.currencyCode }}</span>
          </div>
          @if (priceLine.showVatText) {
            <div class="vatText">
              Prisen er inkl. moms
            </div>
          }
        </div>
      </div>
    }
  </div>
}

@if (!product()?.ctaLink && product()?.canBeAddedToCart && !hideAddToCart()) {
  <form (addedToCart)="addedToCartEvent($event)"
        [product]="product()"
        [eventSourceName]="eventSourceName()"
        [hitPosition]="hitPosition()"
        class="rr-block"
        add-to-cart>
  </form>
}
@if (product()?.ctaLink) {
  <a target="_blank" class="btn btn-primary w-100 cta-link"
     [href]="product()?.ctaLink">{{ product()?.ctaTitle }}</a>
}

@if (!hideProductDefaultCta()) {
  @if ((!product()?.ctaLink || alwaysShowDiscountBtn()) && !hasGroupVoucherPrice()) {
    <a (click)="openContactForOffersModal()" class="discount btn btn-light col-12" data-rr-shared="true">
      Indhent tilbud på storkøb
    </a>
  }
  @if ((!product()?.ctaLink || alwaysShowDiscountBtn()) && hasGroupVoucherPrice()) {
    <a class="discount btn btn-light col-12">Du får allerede storkøbspris</a>
  }
}
@if (showAddedToCart()) {
  <app-add-to-cart-popup (buyMore)="buyMore()"></app-add-to-cart-popup>
}

