@if (product()?.inStock) {
  <input #qtyInput type="number" min="1" class="quantity" name="quantity"
         [value]="product()?.defaultQuantity || 1"/>
  <div class="quantity-controls" data-rr-shared="true">
    <fa-icon class="quantity-up" (click)="adjustQty(1)" [icon]="faAngleUp"></fa-icon>
    <fa-icon class="quantity-down" (click)="adjustQty(-1)" [icon]="faAngleDown"></fa-icon>
  </div>
  <button (click)="addToCart($event)" [disabled]="addingToCart()" class="btn btn-primary col-9"
  >Læg i kurv
  </button>
} @else if (product() && !product()?.inStock) {
  <div class="btn btn-light col-12">
    Udsolgt
  </div>
}

