import {Component, input} from '@angular/core';
import {ProductService} from '@core/services/product.service';
import {CurrencyPipe} from '@angular/common';
import {PricePipe} from '@core/pipes/price.pipe';
import {WineProduct} from '@core/interfaces/generated/graphql';


@Component({
  selector: 'app-product-save-label',
  templateUrl: './product-save-label.component.html',
  styleUrls: ['./product-save-label.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, PricePipe]
})
export class ProductSaveLabelComponent {

  product = input.required<WineProduct | undefined>();

  lastPrice = this.productService.getDefaultPriceSignal(this.product);

  constructor(
    protected productService: ProductService
  ) {
  }


  parseInt(str: any): number {
    return str;
  }
}
