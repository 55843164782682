import {Component, computed, Input} from '@angular/core';
import {CartService} from '@core/services/cart.service';
import {UserInitService} from '@core/services/user-init.service';
import {GroupVoucherService} from '@core/services/groupVoucher.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {UserService} from '@core/services/user.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-superpay-buy-btn',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe
  ],
  templateUrl: './superpay-buy-btn.component.html',
  styleUrls: ['./superpay-buy-btn.component.scss']
})
export class SuperpayBuyBtnComponent {

  @Input() type: 'checkout' | 'add-to-cart' | 'age-verification-embedded' | 'authentication' = 'checkout';
  @Input() productId: string|undefined;
  @Input() referer: string|undefined;
  @Input() label: string = ' - KLIK HER';
  @Input() qty: number|undefined;

  cartSignal = this.cartService.cartSignal;
  groupVoucherSignal = this.groupVoucherService.groupVoucherSignal;
  renderSignal = this.initRenderSignal();
  customerToken = this.userService.userToken;

  constructor(
    protected cartService: CartService,
    protected initService: UserInitService,
    private groupVoucherService: GroupVoucherService,
    private userService: UserService
  ) {
  }

  initRenderSignal() {
    return computed(() => {
      if (!environment.superpay.enabled) {
        return false;
      }

      const cart = this.cartSignal()?.cart;
      const userInit = this.initService.onInitializedSignal();
      if (this.type === 'authentication' && userInit) {
        return true;
      }
      if (!cart?.id) {
        return false;
      }
      if (!this.initService.onInitializedSignal()) {
        return false;
      }
      if (this.groupVoucherSignal().groupVoucher?.delivery === 1) {
        return false;
      }
      return this.cartService.getTestCase(cart, 'show_sp_iframe_btn') === 1;
    })
  }

}
