import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {CartService} from '@core/services/cart.service';
import {ProductBase, WineProduct} from '@core/interfaces/generated/graphql';
import {GoogleService} from '@core/services/google.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AddToCartPopupComponent} from './add-to-cart-popup/add-to-cart-popup.component';
import {NgIf} from '@angular/common';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'form[add-to-cart]',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, NgIf, AddToCartPopupComponent]
})
export class AddToCartComponent {

  addingToCart = signal(false);

  product = input<WineProduct | ProductBase | undefined>(undefined);
  eventSourceName = input.required<string>();
  hitPosition = input<number>();
  @Output() addedToCart: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('qtyInput') qtyInput: ElementRef<HTMLInputElement>;

  @HostBinding('class') class = 'no-gutters';

  constructor(
    private cartService: CartService,
    private googleService: GoogleService
  ) {
  }

  @HostListener('submit', ['$event'])
  formSubmit(event: any) {
    event.preventDefault();
    this.addToCart();
  }


  adjustQty(amount: any) {
    const min = parseInt(this.qtyInput.nativeElement.min, 10);
    const max = parseInt(this.qtyInput.nativeElement.max, 10);
    const currentValue = parseInt(this.qtyInput.nativeElement.value, 10);
    const adjustedValue = currentValue + amount;

    if (adjustedValue < min || adjustedValue > max) {
      return;
    }

    this.qtyInput.nativeElement.value = adjustedValue;
  }


  addToCart(event?: MouseEvent) {
    const product = this.product();
    event?.preventDefault();
    const productId = parseInt(<string>product?.productId, 10);
    const qty = parseInt(this.qtyInput?.nativeElement?.value, 10);
    const vismaId = (product as any)?.vismaProductNumber?.toString() ?? '';
    if (!product || !productId || !qty) {
      console.warn('addToCart: product, productId or qty is missing', {product, productId, qty})
      // This should never happen, so it's important we through an error that will be caught by Sentry
      throw new Error('addToCart: product, productId or qty is missing for product id: #' + product?.id);
    }
    const isPreorder = !!product?.preorderDate;
    const isEnPrimeur = !!(product as WineProduct)?.isEnPrimeur;

    this.cartService.addToCart(vismaId, productId as any, qty as any, this.eventSourceName(), undefined, this.hitPosition(), isPreorder, isEnPrimeur)
      .then(() => {
        this.googleService.addProductToCartEvent(product, qty);
        this.addedToCart.emit(true);
      })
      .finally(() => {
        this.addingToCart.set(false)
      })
      .catch(error => {
        this.addedToCart.emit(false);
        throw error;
      })
    this.addingToCart.set(true)
  }

  protected readonly faAngleUp = faAngleUp;
  protected readonly faAngleDown = faAngleDown;
}
