<app-instantsearch #instantSearch (changeEvent)="hitsChange($event)" *ngIf="filtersSignal() as filters"
                   [config]="getSearchConfig(filters)">
  <app-instantsearch-configure [searchParameters]="configuration"></app-instantsearch-configure>
  <app-search-page-title [title]="title"></app-search-page-title>

  <app-instantsearch-search-box [searchAsYouType]="true" class="d-none"></app-instantsearch-search-box>
  <div id="supervin-instantsearch">
    <app-top-filter [filters]="filters" [hasLoadedHits]="hasLoadedHits()" [hits]="hits()"></app-top-filter>

    <div class="search-results">
      <div [style.top]="topFilterHeight()" class="search-results-filters-wrapper sticky-top">
        <div class="container">
          <app-top-filter-active [shownFilters]="shownFilters(filters)" class="filter-style"></app-top-filter-active>
        </div>
      </div>


      <div class="container">
        <div class="search-results-top">
          <app-instantsearch-stats class="flex-grow-1"></app-instantsearch-stats>
          <div class="toggles hide-element-on-mobile-filter-active">
            <app-instantsearch-toggle
              *ngIf="!hideDiscountFilter"
              [label]="'Tilbud'"
              [on]="1"
              attribute="on_discount"
            ></app-instantsearch-toggle>
            <app-instantsearch-toggle
              [label]="'Anmeldte'"
              [on]="1"
              attribute="is_rated"
            ></app-instantsearch-toggle>
            @if (sortsSignal().length > 1) {
              <app-instantsearch-sort-by [sortOptions]="sortsSignal()"></app-instantsearch-sort-by>
            }
          </div>
        </div>
        <div class="search-results-hits row">
          <app-loading-icon *ngIf="!ready()" id="rendered"></app-loading-icon>

          <ng-template #productsTemplate let-item="item" let-i="index" let-sendEvent="sendEvent">
            <article [product]="item.wineProduct"
                     [queryID]="item.wineProduct.__queryID"
                     [sendEvent]="sendEvent"
                     [hideProductDefaultCta]="hideProductDefaultCta"
                     [index]="i"
                     app-product-widget
                     eventSourceName="algolia-list-page">
            </article>
          </ng-template>

          <app-search-page-results [hitMinWidthPx]="325" [hitsTemplate]="productsTemplate" #searchResults [hideProductDefaultCta]="hideProductDefaultCta" [sortBy]="sortBy"
                                   class="col-md-12"></app-search-page-results>
          <app-sad-wineglass-icon
            *ngIf="ready() && hits() === 0"
            [text]="'Åh nej, jeg kunne ikke finde nogen resultater!'"
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-start justify-content-center mb-4"></app-sad-wineglass-icon>
        </div>
      </div>
    </div>
  </div>
</app-instantsearch>
