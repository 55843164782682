import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-qty-input',
  templateUrl: './qty-input.component.html',
  styleUrls: ['./qty-input.component.scss'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    FormsModule
  ]
})
export class QtyInputComponent implements OnInit, OnDestroy {

  @Input() qty: number = 1;

  @Input() small = false;
  @Input() enableDebounce = false;
  @Output() qtyChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() submitEvent: EventEmitter<number> = new EventEmitter<number>();
  updateQtyEmitter: Subject<number> = new Subject<number>();
  updateQtySub: Subscription;

  constructor() {
  }

  @HostBinding('class.small') get smallClass() {
    return this.small;
  }

  ngOnInit() {
    this.updateQtySub = this.updateQtyEmitter.pipe(
      debounceTime(this.enableDebounce ? 800 : 0),
    ).subscribe(qty => {
      this.qtyChange.emit(qty);
    });
  }

  ngOnDestroy() {
    this.updateQtySub.unsubscribe();
  }

  onEnter(event: any) {
    event.preventDefault();
    event.target.blur();
    this.submitEvent.emit(this.qty);
  }

  minusProduct() {
    (this.qty as number)--

    this.updateQtyEmitter.next(this.qty);
  }

  plusProduct() {
    (this.qty)++

    this.updateQtyEmitter.next(this.qty);
  }

  onInputBlur($event: any) {
    let value = $event.target.value;
    if (value === '') {
      value = 0;
    }
    const qty = parseInt(value, 10);
    this.qtyChange.emit(qty);
  }

  protected readonly faMinus = faMinus;
  protected readonly faPlus = faPlus;
}
