import {Component, input} from '@angular/core';
import {Rating} from '@core/interfaces/generated/graphql';
import {NgIf} from '@angular/common';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {ProductRatingPointsComponent} from './product-rating-points/product-rating-points.component';


@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.scss'],
  standalone: true,
  imports: [NgIf, ExistsPipe, ProductRatingPointsComponent]
})
export class ProductRatingComponent {

  rating = input.required<Rating>()

}
